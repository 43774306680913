<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Thêm mới phiếu chuyển cửa hàng'">
      <template v-slot:preview>
        <b-form class="col-md-12 mb-5">
          <b-row>
            <b-col md="3">
              <label
                >Họ Tên, Mã NV:
                <Autosuggest
                  :model="searchEmployee"
                  :suggestions="filteredOptionsEmployees"
                  aria-describedby="input-employee-name-code"
                  size="sm"
                  @select="onSelectedEmployee"
                  :limit="10"
                  @change="onInputChangeEmployee"
                  suggestionName="suggestionName"
                  placeholder="Nhập tên hoặc mã nhân viên"
                >
                </Autosuggest>
              </label>
            </b-col>

            <b-col md="3">
              <label
                >Cửa hàng hiện tại:
                <b-input
                  type="text"
                  size="sm"
                  v-model="currentStoreName"
                  single-line
                  hide-details
                  disabled
              /></label>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col md="3">
              <label
                >Cửa hàng muốn chuyển đến
                <Autosuggest
                  :model="searchTargetStore"
                  :suggestions="filteredOptions"
                  @select="onSelectedTargetStore"
                  :limit="limitPerPage"
                  @change="onInputChangeTargetStore"
                  placeholder="Nhập tên cửa hàng muốn chuyển đến"
                  suggestionName="suggestionName"
                >
                </Autosuggest>
              </label>
            </b-col>
            <b-col md="3">
              <label
                >Ghi chú:
                <b-form-textarea
                  id="textarea"
                  size="sm"
                  v-model="description"
                  placeholder="Lý do chuyển cửa hàng"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </label>
            </b-col>
          </b-row>
          <b-button variant="primary" size="sm" @click="onSubmit"
            >Tạo phiếu</b-button
          >
        </b-form>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { TIME_TRIGGER } from '@/utils/constants';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import KTCodePreview from '@/view/content/CodePreview.vue';
import debounce from 'debounce';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';

export default {
  data() {
    return {
      searchEmployee: '',
      selectedEmployeeId: null,
      currentStoreId: null,
      targetStoreId: null,
      currentStoreName: null,
      filteredOptions: [],
      searchTargetStore: '',
      description: '',
      limitPerPage: 100,
      isSearching: false,
      isLoading: false,
      optionsEmployees: [
        {
          data: [],
        },
      ],
      filteredOptionsEmployees: [],
      options: [{ data: [] }],
    };
  },
  created() {
    this.fetchStore();
    this.startedTyping = _.debounce(this.startedTyping, 500, {
      leading: true,
      trailing: false,
    });
    this.stoppedTyping = _.debounce(this.stoppedTyping, 500, {
      leading: false,
      trailing: true,
    });
  },
  methods: {
    onFilter() {
      this.isSearching = true;
      this.$route.query.page = 1;
      this.fetchEmployee();
    },
    fetchEmployee: async function () {
      this.onLoading = true;
      this.isSearching = true;

      const params = {
        page: this.page,
        pageSize: 10,
        fullName: this.searchEmployee,
      };

      const paramQuery = {
        params,
      };

      ApiService.query('employees', paramQuery).then((response) => {
        this.employees = response.data.data.employees;

        const employeesList = this.employees || [];

        this.optionsEmployees[0].data = employeesList;

        this.isSearching = false;

        const employeesListResult = employeesList.map((employee) => ({
          ...employee,
          suggestionName: this.getSelectedEmployeeInfo(employee),
        }));

        this.filteredOptionsEmployees = [...employeesListResult];

        this.onLoading = false;
      });
    },

    onSelectedEmployee(option) {
      this.searchEmployee = this.getSelectedEmployeeInfo(option.item);
      this.selectedEmployeeId = option.item.id;
      this.currentStoreId = option.item.storeId;
      this.currentStoreName = option.item.storeName || 'Chưa có';
    },

    getSelectedEmployeeInfo(info) {
      const fullName = info.fullName;
      const code = info.code;
      const jobTitleName = info.jobTitleName;
      return [fullName, code, jobTitleName].filter(Boolean).join(' - ').trim();
    },
    fetchStore: async function () {
      this.options[0].data = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;

        stores.map((element) => {
          const stroreResult = {
            ...element,
            suggestionName: element.name
              ? element.shortName + ' (' + element.name + ')'
              : element.shortName,
          };
          this.options[0].data.push(stroreResult);
        });

        this.filteredOptions = [...this.options[0].data];
      });
    },
    showSuccess: function () {
      Swal.fire({
        title: 'Thông báo',
        text: 'Thêm nhân viên thành công',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      });
    },
    getSuggestionEmployeeValue(suggestion) {
      const suggestions = [];
      for (let value of Object.values(suggestion.item)) {
        if (value && value.trim()) {
          suggestions.push(value);
        }
      }
      return suggestions.join(' - ');
    },
    onSelectedTargetStore(option) {
      this.targetStoreId = option.item.id;
      this.searchTargetStore = option.item.name;
    },
    onInputChangeTargetStore(text) {
      if (!text) {
        text = '';
      }
      this.searchTargetStore = text;

      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [...filteredData];
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;
      this.optionsEmployees[0].data = [];
      this.filteredOptionsEmployees = [...this.optionsEmployees[0].data];

      this.debounceInput();
    },
    debounceInput: debounce(function () {
      if (!this.isSearching) this.fetchEmployee(this.searchEmployee);
    }, TIME_TRIGGER),
    handleKeydown() {
      this.startedTyping();

      this.stoppedTyping(this.onFilter);
    },
    onSubmit: async function () {
      this.errors = [];
      if (!this.selectedEmployeeId) {
        this.errors.push('Không tìm thấy mã nhân viên.');
      }
      if (!this.targetStoreId) {
        this.errors.push('Cửa hàng muốn chuyển đến là bắt buộc.');
      }

      if (this.targetStoreId === this.currentStoreId) {
        this.errors.push(
          'Cửa hàng muốn chuyển đến không được giống với cửa hàng hiện tại'
        );
      }

      if (this.errors.length) {
        this.errors.forEach((msg) => {
          this.makeToastFaile(msg);
        });
      } else {
        await this.createForm();
      }
    },
    createForm: async function () {
      const data = {
        data: {
          employeeId: this.selectedEmployeeId,
          currentStoreId: this.currentStoreId,
          targetStoreId: this.targetStoreId,
          description: this.description,
        },
      };
      const apiAction = this.id ? ApiService.put : ApiService.post;

      try {
        const res = await apiAction('transfer-store-forms', data);
        if (res.data.status === 1) {
          makeToastSuccess(res.data.message);
          this.$router.go(-1);
        } else {
          makeToastFaile(data.message);
        }
      } catch (error) {
        makeToastFaile(
          error.response.data ? error.response.data.message : 'Lỗi'
        );
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu chuyển cửa hàng', route: 'transfer-store-forms' },
      { title: 'Thêm mới phiếu chuyển cửa hàng' },
    ]);
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  components: { KTCodePreview, Loader, Autosuggest },
};
</script>

<style>
label {
  width: 100%;
}
</style>
